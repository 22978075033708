body {
    position: relative;

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Marcellus SC', 'Legan', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Marcellus SC';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLE_iNacKKg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Marcellus SC';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLE_htac.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Legan';
    font-style: italic;
    font-weight: 500;
    src: url(/public/Legan.woff) format('woff');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Legan';
    font-style: italic;
    font-weight: 500;
    src: url(/public/Legan.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


.header-logo {
    margin: 0 20px;
    color: #0e0e0e;
    font-family: "Marcellus SC", serif;
    font-weight: 600;
    text-decoration: none;
}

.header-logo a {
    margin: 0 20px;
    color: #0e0e0e;
    font-family: "Marcellus SC", serif;
    font-weight: 600;
    text-decoration: none;
}

.no-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* IE/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}


.styled-border {
    border: solid 2px transparent; /* Set the border to transparent */
    border-radius: 8px;
    padding: 5px;
    position: relative;
    background-clip: padding-box; /* Prevent the background from extending under the border */
    background: #fff; /* Your element background color */
    flex-grow: 1;
}

.styled-border {
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent) 1 stretch;
}

.styled-border.accent {
    border-image: linear-gradient(to bottom, rgba(224, 136, 33), transparent) 1 stretch;
}